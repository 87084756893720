import { useContext, useEffect, useRef, useState } from "react"
import { successContext } from "../App"
import 'js-circle-progress'
import version from '../version'

export const UploadFile = () => {
    const { success, setSuccess } = useContext(successContext)
    const [message, setMessage] = useState({
        message: '',
        success: false,
        show: false
    })
    const [isLoading, setIsLoading] = useState(false) 
    const [progress, setProgress] = useState(0)
    const [attachment, setAttachment] = useState([])
    const [fileId, setFileId] = useState([])
    const [progressList, setProgressList] = useState({
        data: {
        }
    })

    const abortControllerRef = useRef(null)

    const { VITE_EXPRESS } = import.meta.env 

    const handleProgressData = (newData) => {

        setProgressList((prevProgressList) => {
            const { data } = prevProgressList
            const newDataKey = `data_${newData.fileId}`
            return {
                ...prevProgressList,
                data: {
                    ...data,
                    [newDataKey]: {
                        progress: Math.round(newData.progress),
                        fileId: newData.fileId
                    }
                }
            }
        })
    }

    const uploadStock = async (files, fileIds) => {
        setIsLoading(true)
        setMessage({
            message: 'Iniciando...',
            success: true,
            show: true
        })

        try {
            const formData = new FormData()
            files.forEach((file, index) => {
                formData.append('files', file)
                formData.append('fileIds', fileIds[index])
            })

            abortControllerRef.current = new AbortController()
            const { signal } = abortControllerRef.current
            const options = {
                method: 'POST',
                body: formData,
                signal
            }

            const response = await fetch(`${VITE_EXPRESS}/upload/stock`, options)

            if (!response.ok) {
                throw new Error("Error al subir los archivos")
            }

            const message = response.headers.get('X-Message')
            if (message) {
                setMessage({
                    message: 'Mensaje del servidor:',
                    success: false,
                    show: true
                })
            }

            const reader = response.body.getReader();
            const decoder = new TextDecoder();
            let done = false;
            let buffer = '';

            while (!done) {
                const { value, done: readerDone } = await reader.read();
                done = readerDone;

                if (value) {
                    const chunk = decoder.decode(value, { stream: true });
                    buffer += chunk;

                    const lines = buffer.split('\n');
                    buffer = lines.pop();

                    for (const line of lines) {
                        if (line.startsWith('data: ')) {
                            try {
                                const progressData = line.replace('data: ', '');
                                const parsedProgressData = JSON.parse(progressData);

                                if (parsedProgressData.progress) {
                                    handleProgressData(parsedProgressData);
                                } else if (parsedProgressData.message) {
                                    // Determinar si el mensaje es de error
                                    const isError = parsedProgressData.message.includes('No se encontró') ||
                                                    parsedProgressData.message.includes('vacío');

                                    setMessage({
                                        message: parsedProgressData.message,
                                        success: !isError, // Es exitoso si no es error
                                        show: true
                                    });
                                }
                            } catch (error) {
                                console.error('Error al parsear el JSON:', error);
                            }
                        } else {
                            const cleanMessage = line.replace(/^message:\s*{"message":\s*"([^"]+)"\s*(?:,|$)/, '$1');

                            if (cleanMessage) {
                                // Determinar si el mensaje es de error
                                const isError = cleanMessage.includes('No se encontró') || cleanMessage.includes('vacío');

                                setMessage({
                                    message: cleanMessage,
                                    success: !isError,
                                    show: true
                                });
                            }
                        }
                    }
                } else {
                    setMessage({
                        message: 'No se recibió ningún valor en esta lectura.',
                        success: false,
                        show: true
                    });
                }
            }
        } catch (error) {
            console.error('Error en la carga:', error)
            setMessage({
                message: 'Error al cargar los archivos',
                success: false
            })
            setSuccess(false)
        } finally {
            setIsLoading(false)
        }
    }

    const handleUpload = async () => {
        if (attachment.length > 0) {
            try {
                await uploadStock(attachment, fileId)
                setMessage({
                    message: 'Todos los archivos han sido importados exitosamente',
                    success: true
                })
            } catch (error) {
                setMessage({
                    message: 'Error al subir algunos archivos',
                    success: false
                })
            }
        } else {
            setMessage({
                message: 'Por favor, selecciona un archivo',
                success: false
            })
        }
    }

    const handleFiles = (e) => {
        const files = e.dataTransfer ? e.dataTransfer.files : e.target.files
        const chooseFiles = Array.from(files)
    
        if (chooseFiles.length > 0) {
            setAttachment(chooseFiles)
            const generateFileId = chooseFiles.map(() => Math.floor(Math.random() * 1000))
            setFileId(generateFileId)
        } else {
            setMessage({
                message: 'Por favor, selecciona un archivo',
                success: false
            })
        }
    }
    

    const removeFile = (index) => {
        const updatedAttachment = attachment.filter((file, i) => i !== index)
        const updatedFileId = fileId.filter((id, i) => i !== index)
        setAttachment(updatedAttachment)
        setFileId(updatedFileId)
    }

    useEffect(() => {
        const handleBeforeUnload = () => {
            // Aborta la carga cuando se recarga la página
            if (abortControllerRef.current) {
                abortControllerRef.current.abort()
            }
        }

        window.addEventListener('beforeunload', handleBeforeUnload)

        return () => {
            // Limpia el evento al desmontar el componente
            window.removeEventListener('beforeunload', handleBeforeUnload)
        }
    }, [])

    useEffect(() => {
        if (message && message.show === true) {
            const messageAlert = document.querySelector('.Messages')
            setTimeout(() => {
                messageAlert.classList.add('Messages--show')
            }, 200)
            setTimeout(() => {
                messageAlert.classList.remove('Messages--show')
            }, 3500)
            setTimeout(() => {
                setMessage(null)
            }, 4000)
        }
        
    }, [message, success, attachment])

    useEffect(() => {       
        let dropArea = document.getElementById('stockForm')
        let uploadForms = document.querySelectorAll('.UploadForms-form')

        // Función para prevenir el comportamiento por defecto
        function preventDefaults(e) {
            e.preventDefault()
            e.stopPropagation()
        }

        // Función para agregar clase de resaltado
        function highlight() {
            uploadForms.forEach(item => { item.classList.add('highlight') })
        }

        // Función para remover clase de resaltado
        function unhighlight() {
            uploadForms.forEach(item => { item.classList.remove('highlight') })
        }

        // Función para manejar los archivos en el drop
        function handleDrop(e) {
            const file = e.dataTransfer.files
            const chooseFiles = Array.prototype.slice.call(file)

            if (chooseFiles) {
                setAttachment(chooseFiles)
                
                const generateFileId = chooseFiles.map(() => {
                    const random = Math.random() * 1000
                    const normal = Math.floor(random)
                    return normal
                })
                setFileId(generateFileId)

            } else {
                setMessage('Por favor, selecciona un archivo') // Mensaje si no hay archivo
            }
        }

        // Añadir los event listeners una vez
        ;['dragenter', 'dragover', 'dragleave', 'drop'].forEach(eventName => {
            dropArea.addEventListener(eventName, preventDefaults, false)
        })

        ;['dragenter', 'dragover'].forEach(eventName => {
            dropArea.addEventListener(eventName, highlight, false)
        })

        ;['dragleave', 'drop'].forEach(eventName => {
            dropArea.addEventListener(eventName, unhighlight, false)
        })

        dropArea.addEventListener('drop', handleDrop, false)


        // Cleanup: remover los event listeners al desmontar el componente
        return () => {
            ;['dragenter', 'dragover', 'dragleave', 'drop'].forEach(eventName => {
                dropArea.removeEventListener(eventName, preventDefaults, false)
            })

            ;['dragenter', 'dragover'].forEach(eventName => {
                dropArea.removeEventListener(eventName, highlight, false)
            })

            ;['dragleave', 'drop'].forEach(eventName => {
                dropArea.removeEventListener(eventName, unhighlight, false)
            })

            dropArea.removeEventListener('drop', handleDrop, false)
        }

        
    }, [message, success, attachment, fileId])
    
    useEffect(() => {
        // Asegura que ambas listas tengan datos y longitudes coincidentes
        if (Object.keys(progressList.data).length === attachment.length && Object.keys(progressList.data).length > 0) {
            console.log("Verificando progreso:", progressList.data) // Verifica el estado de progreso
            const allProgressComplete = Object.values(progressList.data).every(
                (item) => item && item.progress === 100
            )
            console.log("¿Todo el progreso completo?", allProgressComplete)
            
            if (allProgressComplete) {
                setMessage({
                    message: 'Todos los archivos han sido subidos exitosamente',
                    success: true,
                    show: true
                })
                setTimeout(() => {
                    setSuccess(true)
                }, 4000)
            }
        }
    }, [progressList, attachment])
    
    

    return (
        <>
            <successContext.Provider value={success}>
                <div className="UploadForms">  
                    <div className="UploadForms-dragDrop" id="stockForm">
                        <button onClick={ () => { setSuccess(true) }} className="Button Button--go">Ir al generador
                            <svg className="Button-icon" viewBox="0 0 20 20">
                                <path d="M10,0C4.5,0,0,4.5,0,10s4.5,10,10,10s10-4.5,10-10S15.5,0,10,0z M10,18.6c-4.8,0-8.6-3.9-8.6-8.6
                                    c0-4.8,3.9-8.6,8.6-8.6c4.8,0,8.6,3.9,8.6,8.6C18.6,14.8,14.8,18.6,10,18.6z"/>
                                <path d="M10.7,5.3c-0.2-0.2-0.5-0.2-0.7,0c-0.2,0.2-0.2,0.5,0,0.7l3.6,3.6H6.4c-0.3,0-0.5,0.2-0.5,0.5
                                    s0.2,0.5,0.5,0.5h7.2l-3.6,3.6c-0.2,0.2-0.2,0.5,0,0.7c0.1,0.1,0.3,0.2,0.4,0.2s0.3-0.1,0.4-0.2l4.2-4.2
                                    c0.1-0.1,0.2-0.3,0.2-0.4s-0.1-0.3-0.2-0.4L10.7,5.3z"/>
                            </svg>
                        </button>
                        <h2>Subir archivos de stock</h2>
                        <form className="UploadForms-form">
                        {/* Barra de carga */}
                                {/* {progress > 0 && 
                                <div className="progressCircle">
                                    <circle-progress value={`${progress}`} max="100"></circle-progress>
                                    <p>Subiendo archivo...</p>
                                </div>
                                } */}
                                { progress <= 0 && 
                                <>
                                    <span className="UploadForms-icon">
                                        <svg id="flecha-q" viewBox="-5 0 70 55" >
                                            <path id="bloque" d="M58.5,35.7v14.5c0,5.5-4.5,9.9-9.9,9.9H9.9c-5.5,0-9.9-4.5-9.9-9.9V35.7c0-1.4,1.1-2.5,2.5-2.5S5,34.3,5,35.7
                                                v14.5c0,2.7,2.2,4.9,4.9,4.9h38.6c2.7,0,4.9-2.2,4.9-4.9V35.7c0-1.4,1.1-2.5,2.5-2.5S58.5,34.3,58.5,35.7z"/>
                                            <path id="punta" d="M44.9,17.5c-0.9,0.9-2.6,0.9-3.5,0l-9.7-9.6v30.5c0,1.4-1.1,2.5-2.5,2.5s-2.5-1.1-2.5-2.5V7.8l-9.6,9.7
                                                c-1,1-2.6,1-3.5,0c-1-1-1-2.6,0-3.5L27.5,0c1-1,2.6-1,3.5,0l13.9,13.9C45.9,14.9,45.9,16.5,44.9,17.5z"/>
                                        </svg>
                                    </span>
                                    { message === 'Iniciando...' ?
                                        <p>Iniciando...</p>
                                    :   <label className="button" htmlFor="fileElem">Arrastre sus archivos</label>
                                    }
                                 
                                </>
                                }
                            <input type="file" id="fileElem" accept=".csv" multiple onChange={handleFiles}/>
                        </form>
                                           
                    <div className="Attachments">
                            <div className="Attachments-wrapper">
                            <h3 className="Attachments-title">Archivos seleccionados</h3>
                                { attachment && attachment.length > 0 &&
                                    <ul className="Attachments-list">
                                    {attachment.map((file, index) => (
                                        <li className="Attachments-item" key={index}>
                                        <div className="Attachments-info">
                                                <span className="Attachments-icon">
                                                    <svg className="file-icon" viewBox="0 0 20 20">
                                                        <path fill="none" d="M17.206,5.45l0.271-0.27l-4.275-4.274l-0.27,0.269V0.9H3.263c-0.314,0-0.569,0.255-0.569,0.569v17.062
                                                            c0,0.314,0.255,0.568,0.569,0.568h13.649c0.313,0,0.569-0.254,0.569-0.568V5.45H17.206z M12.932,2.302L16.08,5.45h-3.148V2.302z
                                                            M16.344,17.394c0,0.314-0.254,0.569-0.568,0.569H4.4c-0.314,0-0.568-0.255-0.568-0.569V2.606c0-0.314,0.254-0.568,0.568-0.568
                                                            h7.394v4.55h4.55V17.394z">        
                                                        </path>
                                                    </svg>
                                                </span>
                                            <p>{file.name}</p>
                                        </div>
                                        <div className="Attachments-special">
                                            { progressList.data[`data_${fileId[index]}`] &&
                                                    <div className="Attachments-progress">
                                                        <div className="Attachments-progressBar" style={{width: `${progressList.data[`data_${fileId[index]}`].progress}` + '%'}}>
                                                            <span className="Attachments-progressNumber">{progressList.data[`data_${fileId[index]}`].progress}%</span>
                                                        </div>
                                                    </div>
                                                }
                                                <button onClick={() => { removeFile(index) }} className="Attachments-delete">X</button>
                                        </div>
                                        </li>
                                    ))} 
                                
                                    <button className="Attachments-upload" onClick={handleUpload}>Subir Archivos</button>
                                    </ul>
                                }
                                { attachment.length === 0 && <p>No hay archivos seleccionados</p> }
                            </div>
                        </div>
                    </div>

                    { message && message.success === true ?
                        <div className="Messages Messages--iconed Messages--success">
                            <span className="icon">i</span>
                            <p>{message.message}</p>
                        </div>  
                    : message && message.success === false ?   
                        <div className="Messages Messages--alert">
                            <p>{message.message}</p>
                        </div> 
                    : ''
                    }
                    <div className="version" style={{position: 'fixed', bottom: '5px', right:'15px'}}>{version}</div>
                </div>
            </successContext.Provider>
        </>
    )
}
