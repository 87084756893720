import { useContext, useEffect, useRef, useState } from "react"
import * as XLSX from "xlsx-js-style"
import { successContext } from "../App"



export const SearchBySku = () => {
    
    const todayDate = new Date().toISOString().slice(0, 10)

    const {success, setSuccess }                    = useContext(successContext)
    const [skuData, setSkuData]                     = useState('')
    const [skuDataSale, setSkuDataSale]             = useState('') 
    const [sentQuantities, setSentQuantities]       = useState({}) 
    const [itemData, setItemData]                   = useState('') 
    const [isReadyToGenerate, setIsReadyToGenerate] = useState(false)
    const [statusMessage, setStatusMessage]         = useState('') 
    const [isExcelGenerated, setIsExcelGenerated]   = useState(false) 
    const [excelName, setExcelName]                 = useState(undefined) 
    const [firstWeek, setFirstWeek]                 = useState([])
    const [secondWeek, setSecondWeek]               = useState([])
    const [thirdWeek, setThirdWeek]                 = useState([])
    const [customDay, setCustomDay]                 = useState([])
    const [customDaySelected, setCustomDaySelected] = useState(49)
    const [checked, setChecked]                     = useState(false)

    // referencias de los inputs del nombre del archivo y los días
    const inputRef     = useRef()
    const selecDaysRef = useRef()
    const checkRef     = useRef()

    const generateExcel = async () => {
        setStatusMessage('Recopilando datos...')

        setExcelName(inputRef.current.value || todayDate)
        
        try {
            const { VITE_EXPRESS } = import.meta.env


            const controller = new AbortController()
            const options = {
                method: 'GET',
                headers: { 'Content-Type': 'application/json' },
                signal: controller.signal
            }

            const search     = await fetch(`${VITE_EXPRESS}/stock`, options)
            const searchSale = await fetch(`${VITE_EXPRESS}/sales`, options)

            if (!search.ok || !searchSale.ok) {
                throw new Error("Error en la búsqueda")
            }

            const data     = await search.json()
            const dataSale = await searchSale.json()

            setSkuData(data)
            setSkuDataSale(dataSale)

            // Crear un objeto para almacenar las cantidades enviadas por SKU
            // const quantities   = {}
            // let firstWeek      = {}
            // let secondWeek     = {}
            // let thirdWeek      = {}
            const customDay    = {}
            const days         = selecDaysRef.current.value
            const customDaySet = days

            // Filtra los datos excluyendo aquellos con 'Country' igual a 'GB'
            const filteredData = data.filter(item => item['Country'] !== 'GB')

            // Obtiene solo los objetos con 'Country' igual a 'GB'
            const skuFromGb = data.filter(item => item['Country'] === 'GB')

            // Extrae una lista de 'Merchant SKU' únicos de los elementos de 'skuFromGb'
            const merchantSkus = skuFromGb.map(item => item['Merchant SKU'])

            // Filtra 'dataSale' excluyendo los objetos cuyo 'SKU del vendedor' o 'SKU' esté en 'merchantSkus'
            // o cuyo 'SKU del vendedor' o 'SKU' termine en "lp" sin importar mayúsculas o minúsculas
            const filteredDataSale = dataSale.filter(item => {
                const sku = item['SKU del vendedor'] || item['SKU']  // Selecciona la propiedad que exista

                return (
                    sku && 
                    !merchantSkus.includes(sku) && 
                    !sku.toLowerCase().endsWith("lp")
                )
            })

            // Sumar las cantidades enviadas por SKU
            // filteredDataSale.forEach(item => {
            //     const sku = item['SKU del vendedor'] || item['SKU']
            //     const cantidadEnviada = item['Cantidad enviada'] || item['Cantidad_enviada'] || 0

            //     if (!quantities[sku]) {
            //         quantities[sku] = 0
            //     }
            //     quantities[sku] += cantidadEnviada

            //     const date =  new Date(item['Fecha de compra'])
            //     const date2 = date.getDate()
            //     if (!days) {
                
            //         if (date2 <= 7) {
            //             if (!firstWeek[sku]) {
            //                 firstWeek[sku] = 0
                            
            //             } 
            //             firstWeek[sku]  += cantidadEnviada
            //         } 
            //         if (date2 <= 14) {
            //             if (!secondWeek[sku]) {
            //                 secondWeek[sku] = 0
            //             } 
            //             secondWeek[sku] += cantidadEnviada
                        
            //         }
            //         if (date2 <= 30) {
            //             if (!thirdWeek[sku]) {
            //                 thirdWeek[sku] = 0
            //             } 
            //             thirdWeek[sku]  += cantidadEnviada
                        
            //         }
            //     } else {
                
            //         if (date2 <= days) {
            //             if (!customDay[sku]) {
            //                 customDay[sku] = 0
            //             } 
            //             customDay[sku]  += cantidadEnviada
            //         } 
            //         thirdWeek  = 'undefined'
            //         secondWeek = 'undefined'
            //         firstWeek  = 'undefined'
            //     }
            // })
            
            /* calculo ventas */
            let latestDate = new Date(Math.max(...filteredDataSale.map(item => new Date(item['Fecha de pago']))));

            // Asegurarnos de que la fecha más reciente sea válida
            if (isNaN(latestDate)) {
                console.error("No se encontró una fecha válida en la columna 'Fecha de pago'");
                return;
            }
            
            // Calcular las fechas de los rangos para los últimos 7, 14 y 30 días
            const sevenDaysAgo = new Date(latestDate);
            sevenDaysAgo.setDate(latestDate.getDate() - 7);
            
            const fourteenDaysAgo = new Date(latestDate);
            fourteenDaysAgo.setDate(latestDate.getDate() - 14);
            
            const thirtyDaysAgo = new Date(latestDate);
            thirtyDaysAgo.setDate(latestDate.getDate() - 30);
            
            // Inicializar los objetos de seguimiento para las semanas
            let firstWeek = {};
            let secondWeek = {};
            let thirdWeek = {};
            let quantities = {};
            
            filteredDataSale.forEach(item => {
                const sku = item['SKU del vendedor'] || item['SKU'];
                const cantidadEnviada = item['Cantidad enviada'] || item['Cantidad_enviada'] || 0;
            
                if (!quantities[sku]) {
                    quantities[sku] = 0;
                }
                quantities[sku] += cantidadEnviada;
            
                // Obtener la fecha de pago
                const paymentDate = new Date(item['Fecha de pago']);
            
                // Verificar que la fecha de pago esté dentro del rango de los últimos 30 días
                if (paymentDate > latestDate || paymentDate < thirtyDaysAgo) {
                    return; // Si la fecha está fuera del rango de los últimos 30 días, se omite
                }
            
                // Calcular los días desde la fecha más reciente
                const daysSinceLatest = Math.floor((latestDate - paymentDate) / (1000 * 60 * 60 * 24)) + 1;
            
                // Rango de días: 1-7 (Últimos 7 días)
                if (daysSinceLatest <= 7) {
                    if (!firstWeek[sku]) {
                        firstWeek[sku] = 0;
                    }
                    firstWeek[sku] += cantidadEnviada;
                }
            
                // Rango de días: 8-14 (Últimos 14 días - sin descontar los 7)
                if (daysSinceLatest <= 14) {
                    if (!secondWeek[sku]) {
                        secondWeek[sku] = 0;
                    }
                    secondWeek[sku] += cantidadEnviada;
                }
            
                // Rango de días: 15-30 (Últimos 30 días)
                if (daysSinceLatest <= 30) {
                    if (!thirdWeek[sku]) {
                        thirdWeek[sku] = 0;
                    }
                    thirdWeek[sku] += cantidadEnviada;
                }
            });
            /* fin calculo ventas */

            // Crear un array para almacenar los datos únicos de SKU
            const uniqueSKUData = []
            const skuSet = new Set()
            
            filteredData.forEach(item => {
              const sku = item['Merchant SKU']  
              // Comprobar si el SKU ya está en el Set
              if (!skuSet.has(sku)) {  
                // Si no, añadir al Set
                skuSet.add(sku)  
                 // También añádelo a uniqueSKUData
                uniqueSKUData.push(item) 
              }
            })
            // Establecer los datos de los items
            setItemData(uniqueSKUData)
            setSentQuantities(quantities)
            setFirstWeek(firstWeek)
            setSecondWeek(secondWeek)
            setThirdWeek(thirdWeek)
            setCustomDay(customDay)
            setCustomDaySelected(customDaySet)         

            // Establecer la bandera para generar el Excel
            setIsReadyToGenerate(true)

        } catch (error) {
            setStatusMessage("Error al obtener datos")
            console.error(error)
        }
    }

    const handleChecked = (event) => {
        const selectedValue = event.target.value
        
        // Establece el valor de días según el radio seleccionado
        if (selectedValue === '7') {
          setCustomDaySelected(7)
        } else if (selectedValue === '14') {
          setCustomDaySelected(14)
        } else if (selectedValue === '30') {
          setCustomDaySelected(28)
        } else if (selectedValue === '49') {
          setCustomDaySelected(49)
        }
      
        // Marca el estado de "checked" como verdadero
        setChecked(true)
      }

    useEffect(() => {
        
        if (isReadyToGenerate) {
          
            setStatusMessage('Generando Excel...')
            const wsData = []
            itemData.forEach(item => {
                const sku = item['Merchant SKU']
                const totalUnits = item['Total Units']
                // const cantidadEnviada = sentQuantities[sku] || 0 // Obtener la cantidad enviada correspondiente
                const firstWeekUnits           = firstWeek[sku]   === 'undefined' ? 'undefined' : firstWeek[sku]   || 0
                const secondWeekUnits          = secondWeek[sku]  === 'undefined' ? 'undefined' : secondWeek[sku]  || 0
                const thirdWeekUnits           = thirdWeek[sku]   === 'undefined' ? 'undefined' : thirdWeek[sku]   || 0
                const customDayUnits           = customDay[sku]                                                    || 0
                const sendSevenDays            = firstWeekUnits - totalUnits
                const sendFourteenDays         = firstWeekUnits * 2 - totalUnits -  (sendSevenDays <= 0 ? '' : sendSevenDays)
                const sendTwentyEightDays      = firstWeekUnits * 4 - totalUnits 
                const calculatePercentageFW    = firstWeekUnits === secondWeekUnits ? '1000%' : Math.round(((firstWeekUnits - (secondWeekUnits - firstWeekUnits)) / (secondWeekUnits - firstWeekUnits))  * 10000) / 100 + '%'
                const calculatePercentageSW    = secondWeekUnits === thirdWeekUnits ? '1000%' : Math.round(((secondWeekUnits - (thirdWeekUnits - secondWeekUnits)) / (thirdWeekUnits - secondWeekUnits)) * 10000) / 100 + '%'
                const percentageValue          = parseFloat(calculatePercentageFW)
                const roundedTwentyEightDays   = sendTwentyEightDays <= 0 ? 0 : 
                                                 sendTwentyEightDays <= 5 ? 5 :
                                                 Math.ceil(sendTwentyEightDays / 10) * 10
                const sendTotalFourtyNineDays  = firstWeekUnits * 7 - totalUnits - roundedTwentyEightDays 
                const roundedFourtyNineDays    = sendTotalFourtyNineDays <= 0 ? 0 : 
                                                 (percentageValue < 0 || sendTotalFourtyNineDays % 10 < 5) ? Math.floor(sendTotalFourtyNineDays / 10) * 10 :
                                                 Math.ceil(sendTotalFourtyNineDays / 10) * 10
                const newTwentyEightDays       = roundedFourtyNineDays > 0 && roundedFourtyNineDays <= 30 ? roundedTwentyEightDays + roundedFourtyNineDays : 0
                const finalSendTwentyEightDays = newTwentyEightDays === 0 ? roundedTwentyEightDays : newTwentyEightDays
                const newFourtyNineDays        = roundedFourtyNineDays <= 30 && newTwentyEightDays != 0 ? 0 : roundedFourtyNineDays
              
                wsData.push({
                    'SKU'             : sku,
                    'STOCK'           : totalUnits,
                    '7 días'          : firstWeekUnits,
                    '14 días'         : secondWeekUnits,
                    '28 días'         : thirdWeekUnits,
                    '7-14 días'       : calculatePercentageFW,
                    '14-28 días'      : calculatePercentageSW,
                    'Enviar a 7 días' : sendSevenDays <= 0 ? '' : sendSevenDays,
                    'Enviar a 14 días': sendFourteenDays <= 0 ? '' : sendFourteenDays,
                    'Enviar a 28 días': finalSendTwentyEightDays <= 0 ? '' : finalSendTwentyEightDays,
                    'Enviar a 49 días': newFourtyNineDays <= 0 ? '' : newFourtyNineDays
                })    
            })

            const ws = XLSX.utils.json_to_sheet(wsData)
            const headerThree = ['SKU', 'STOCK', '7 días', '14 días', '28 días', '7-14 días', '14-28 días', 'Enviar a 7 días','Enviar a 14 días', 'Enviar a 28 días', 'Enviar a 49 días']
            XLSX.utils.sheet_add_aoa(ws, [headerThree]  , { origin: "A1" }) 

            Object.keys(ws).forEach(cell => {
                const rowNumber = cell.match(/\d+/) ? cell.match(/\d+/)[0] : null;
                const col = cell.match(/[A-Z]/) ? cell.match(/[A-Z]/)[0] : null;

                if (cell.startsWith('B') && ws[cell].v === 0) { // Columna 'STOCK' es la columna B
                    // Aplicar estilo rojo a todas las celdas de esa fila (todas las columnas)
                    for (let col of ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J']) { // Las columnas pueden ser ajustadas según tus necesidades
                        const cellRef = `${col}${rowNumber}`
                        if (ws[cellRef]) {
                            ws[cellRef].s = {
                                fill: {
                                    fgColor: { rgb: "fc7f7f" } // Color rojo
                                },
                                border: {
                                    top    : { style: "thin", color: { rgb: 'e2e2e2' } },
                                    bottom : { style: "thin", color: { rgb: 'e2e2e2' } },
                                    left   : { style: "thin", color: { rgb: 'e2e2e2' } },
                                    right  : { style: "thin", color: { rgb: 'e2e2e2' } }
                                }
                            }
                        }
                    }
                }            
                // Verificar si la celda H tiene valores mayores a 0
                if (rowNumber && col === 'H' && ws[cell].v > 0) {
                    // Aplicar estilo amarillo a la celda en la columna J
                    const targetCell = `J${rowNumber}`;
                    ws[targetCell].s = {
                        fill: {
                            fgColor: { rgb: "ffff00" } // Color amarillo
                        },
                        border: {
                            top    : { style: "thin", color: { rgb: 'e2e2e2' } },
                            bottom : { style: "thin", color: { rgb: 'e2e2e2' } },
                            left   : { style: "thin", color: { rgb: 'e2e2e2' } },
                            right  : { style: "thin", color: { rgb: 'e2e2e2' } }
                        }
                    }

                    // Verificar si la celda I tiene valores pero la H no
                } else if (rowNumber && col === 'I' && ws[cell].v > 0 && ws[`H${rowNumber}`].v === "") {
                    // Aplicar estilo azul a la celda en la columna J
                    const targetCell = `J${rowNumber}`;
                    ws[targetCell].s = {
                        fill: {
                            fgColor: { rgb: "00b0f0" } // Color azul
                        },
                        border: {
                            top    : { style: "thin", color: { rgb: 'e2e2e2' } },
                            bottom : { style: "thin", color: { rgb: 'e2e2e2' } },
                            left   : { style: "thin", color: { rgb: 'e2e2e2' } },
                            right  : { style: "thin", color: { rgb: 'e2e2e2' } }
                        }
                    }
                }

                // Aplicar estilos solo a la fila 1
                if (cell.startsWith('A1') || cell.startsWith('B1') || cell.startsWith('C1') || cell.startsWith('D1') || cell.startsWith('E1') || cell.startsWith('F1') || cell.startsWith('G1') || cell.startsWith('H1') || cell.startsWith('I1') || cell.startsWith('J1') || cell.startsWith('K1')) {
                    const rowNumber = cell.match(/\d/)[0] // Obtener el número de fila
                    const col = cell.match(/[A-Z]/)[0] // Obtener la letra de la columna
                    const cellRef = col + rowNumber
                    ws[cellRef].s = {
                        alignment: {
                            horizontal : "left",
                            vertical   : "bottom"
                        },
                        font: {
                            bold : true,
                            sz   : 10
                        },
                        border: {
                            top    : { style: "thin", color: { rgb: 'e2e2e2' } },
                            bottom : { style: "thick", color: { rgb: 'e2e2e2' } },
                            left   : { style: "thin", color: { rgb: 'e2e2e2' } },
                            right  : { style: "thin", color: { rgb: 'e2e2e2' } }
                        }
                    }
                }
                if (cell.startsWith('F1') || cell.startsWith('G1')) {
                    const rowNumber = cell.match(/\d/)[0] // Obtener el número de fila
                    const col = cell.match(/[A-Z]/)[0] // Obtener la letra de la columna
                    const cellRef = col + rowNumber
                    ws[cellRef].s = {
                        fill: {
                            fgColor: { rgb: "ffff00" } // Color rojo
                        },
                        alignment: {
                            horizontal : "left",
                            vertical   : "bottom"
                        },
                        font: {
                            bold : true,
                            sz   : 10
                        },
                        border: {
                            top    : { style: "thin", color: { rgb: 'e2e2e2' } },
                            bottom : { style: "thick", color: { rgb: 'e2e2e2' } },
                            left   : { style: "thin", color: { rgb: 'e2e2e2' } },
                            right  : { style: "thin", color: { rgb: 'e2e2e2' } }
                        }
                    }
                }
                if (cell.startsWith('F') || cell.startsWith('G')) {
                    // Obtener la letra de la columna y número de fila
                    const rowNumber = cell.match(/\d+/)[0] 
                    const col       = cell.match(/[A-Z]/)[0]
                    const cellRef   = col + rowNumber
                     if ( cellRef != 'F1' && cellRef != 'G1'){ 
                        ws[cellRef].s = { 
                            fill: {
                                fgColor: { rgb: "e2efd9" } // Color rojo
                            },
                            font: {
                                sz: 11
                            },
                            border: {
                                top    : { style: "thin", color: { rgb: 'e2e2e2' } },
                                bottom : { style: "thick", color: { rgb: 'e2e2e2' } },
                                left   : { style: "thin", color: { rgb: 'e2e2e2' } },
                                right  : { style: "thin", color: { rgb: 'e2e2e2' } }
                            }
                        }
                    }
                }
                if (cell.startsWith('H') || cell.startsWith('I')) {
                    // Obtener la letra de la columna y número de fila
                    const rowNumber = cell.match(/\d+/)[0] 
                    const col       = cell.match(/[A-Z]/)[0]
                    const cellRef   = col + rowNumber
                     if ( cellRef != 'H1' && cellRef != 'I1'){ 
                      
                    }
                }
            })

            const wb = XLSX.utils.book_new()
            XLSX.utils.book_append_sheet(wb, ws, 'Datos SKU')

            // Guardar el archivo
            XLSX.writeFile(wb, excelName + '.xlsx')

            setStatusMessage('Excel generado')
            setIsExcelGenerated(true)
            setIsReadyToGenerate(false)
        }
    }, [isReadyToGenerate, skuData, sentQuantities, excelName, firstWeek, secondWeek, thirdWeek, itemData, checked, customDaySelected])

    

    return (
        <>
            <div className="GenerationPage">

                <div className="GenerationPage-wrapper">
                    <h1 className="title">Generar Excel</h1>
                    { statusMessage === 'Recopilando datos...' ? <p className="GenerationPage-statusMessage">{statusMessage}</p> : statusMessage === 'Excel generado' ? <p className="GenerationPage-statusMessage GenerationPage-statusMessage--success">{statusMessage}</p> : ''}
                    <input ref={inputRef} type="text" name="excelName" placeholder="Añadir un nombre al archivo" className="GenerationPage-setName"/>
                     <input ref={selecDaysRef} placeholder="Elige los días (1-31) que quieres visualizar" disabled type="number" min="1"  max="31" name="selectDays" className="GenerationPage-setName" style={{'display': 'none'}}/>

                    {/* <form ref={checkRef} className="selectionDays">
                        <label htmlFor="siete" className="label-28">
                            <input
                            name="diasEnvio"
                            type="radio"
                            value="7"
                            onChange={handleChecked}
                            />
                            Añadir valores para envío 7 días
                        </label>
                        <label htmlFor="catorce" className="label-28">
                            <input
                            name="diasEnvio"
                            type="radio"
                            value="14"
                            onChange={handleChecked}
                            />
                            Añadir valores para envío 14 días
                        </label>
                      
                    </form> */}
                    <div className="skuSearch">
                            <button className="Button Button--generate" onClick={generateExcel}>
                                {'Generar Excel'}
                            </button>
                        <button onClick={ () => { setSuccess(false) }} className="Button Button--back">
                           X
                        </button>
                    </div>
                </div>
            </div>
        </>
    )
}
